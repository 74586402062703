import { useQuery } from '@tanstack/react-query';
import communication from '../communication';
import { isStatusSuccess } from '../communication/handlers/handleRequest';
import { GET_NotificationsResponse } from '@/communication/notifications/types';
import { ObjectKeys } from '@/types/objectKeys';

const fetchNotifications = async (params: ObjectKeys) => {
    try {
        const response = await communication.getUserNotifications(params);
        if (isStatusSuccess(response.status)) {
            return response.data;
        } else {
            throw new Error('Failed to fetch notifications');
        }
    } catch (error) {
        console.error(error);
    }
};

export const useNotificationsQuery = (params: ObjectKeys) => {
    const {
        data: notifications,
        refetch,
        isLoading,
        error,
    } = useQuery<GET_NotificationsResponse['data'] | undefined>({
        queryKey: ['notifications', params],
        queryFn: () => fetchNotifications(params),
        staleTime: 1000 * 60 * 10, //10 minutes
    });

    return { notifications, refetch, isLoading, error };
};
