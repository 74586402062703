import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import colors from '../../../../../global/colors';
import { faEdit, faTrash, faChevronDown, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transformDateFormat } from '../../../../../functions/formatDateTime';
import parse from 'html-react-parser';

interface NotificationCardProps {
    id: string;
    title_of_alert_rs: string;
    title_of_alert_en: string;
    title_of_alert_ru: string;
    description_rs: string;
    description_en: string;
    description_ru: string;
    updated_at: string;
    total_alerts: number;
    read_alerts: number;
    status: string;
}

interface Props {
    service: NotificationCardProps;
    onEdit: (id: string) => void;
    onDelete: (id: string) => void;
    onSend: (id: string) => void;
    t: Function;
}

const NotificationCard: FunctionComponent<Props> = ({ service, onEdit, onDelete, onSend, t }) => {
    const {
        id,
        title_of_alert_rs,
        title_of_alert_en,
        title_of_alert_ru,
        description_rs,
        description_en,
        description_ru,
        updated_at,
        total_alerts,
        read_alerts,
        status,
    } = service;
    const [expanded, setExpanded] = useState(false);

    const returnStatus = (stat: string): string => {
        switch (stat) {
            case 'draft':
                return t('pages.notifications.statusBO.draft').text;
            case 'sent':
                return t('pages.notifications.statusBO.sent').text;
            default:
                return t('pages.notifications.statusBO.draft').text;
        }
    };

    const handleExpand = () => setExpanded(!expanded);

    return (
        <StyledCard>
            <CardContent>
                <InfoWrapper>
                    <div className="title-col">
                        <div className="label">Naslov</div>
                        <div className="title">{title_of_alert_rs}</div>
                    </div>
                    <div className="date-col">
                        <div className="label">Datum</div>
                        <div className="date">{transformDateFormat(updated_at)}</div>
                    </div>
                    <div className="views-col">
                        <div className="label">Poslato</div>
                        <div className="views">{total_alerts}</div>
                    </div>
                    <div className="views-col">
                        <div className="label">Pregledano</div>
                        <div className="views">{read_alerts}</div>
                    </div>
                    <div className="status-col">
                        <div className="label">Status</div>
                        <div className={`status ${status.toLowerCase()}`}>
                            <span>{returnStatus(status)}</span>
                        </div>
                    </div>
                    <div className="action-col">
                        {status === 'draft' ? (
                            <>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        onClick={() => onSend(id)}
                                        className="icon send-icon"
                                        color={colors.purple}
                                    />
                                    <div className="label">{'Pošalji'}</div>
                                </Icon>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        onClick={() => onEdit(id)}
                                        className="icon edit-icon"
                                        color={colors.lightBlue}
                                    />
                                    <div className="label">{'Izmeni'}</div>
                                </Icon>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        onClick={() => onDelete(id)}
                                        className="icon delete-icon"
                                        color={colors.danger}
                                    />
                                    <div className="label">{'Obriši'}</div>
                                </Icon>
                            </>
                        ) : (
                            <FontAwesomeIcon icon={faChevronDown} onClick={handleExpand} className="icon expand-icon" />
                        )}
                    </div>
                </InfoWrapper>
                {expanded && status !== 'draft' && (
                    <ExpandedContent>
                        <div className="title-en">
                            <div className="label">Title (EN)</div>
                            <div className="content">{title_of_alert_en}</div>
                        </div>
                        <div className="title-ru">
                            <div className="label">Title (RU)</div>
                            <div className="content">{title_of_alert_ru}</div>
                        </div>
                        <div className="message-rs">
                            <div className="label">Message (RS)</div>
                            {parse(description_rs)}
                        </div>
                        <div className="message-en">
                            <div className="label">Message (EN)</div>
                            {parse(description_en)}
                        </div>
                        <div className="message-ru">
                            <div className="label">Message (RU)</div>
                            {parse(description_ru)}
                        </div>
                    </ExpandedContent>
                )}
            </CardContent>
        </StyledCard>
    );
};

const StyledCard = styled.div`
    margin-bottom: 10px;
    padding: 16px 20px;
    border-radius: 5px;
    width: 100%;
    background: var(--white);
    border: 1px solid var(--purple);
    transition: all 0.5s ease-in-out;
    &:hover {
        box-shadow: 0 0 5px 5px #eee;
        .info-wrapper {
            opacity: 0.7;
        }
    }
`;

const Icon = styled.div`
    position: relative;

    .label {
        opacity: 0;
        position: absolute;
        left: 50%;
        top: -20px;
        transform: translate(-50%, -50%);
        padding: 10px;
        border-radius: 4px;
        z-index: 9;
        font-size: 15px;
    }

    &:hover {
        .label {
            top: -40px;
            opacity: 1;
            transition: top 0.5s, opacity 0.5s;
            background: var(--gray);
            color: var(--white);
            z-index: 1;
        }
    }
`;

const CardContent = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
`;

const InfoWrapper = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    .label {
        font-size: 12px;
        color: var(--gray);
        line-height: 25px;
    }
    .status {
        text-transform: capitalize;
        span {
            padding: 0 10px 2px;
            background: var(--gray);
            color: var(--white);
            border-radius: 50px;
            font-size: 12px;
        }
        &.sent {
            span {
                background: var(--green);
            }
        }
        &.inactive {
            span {
                background: var(--danger);
            }
        }
    }
    .icon {
        cursor: pointer;
        margin-left: 10px;
        font-size: 16px;
        &:hover {
            color: var(--gray);
        }
    }
    .action-col {
        display: flex;
        justify-content: flex-end;
    }
    .title-col {
        max-width: 200px;
        .title {
            word-wrap: break-word;
            white-space: normal;
        }
    }
`;

const ExpandedContent = styled.div`
    margin-top: 10px;
    .label {
        font-size: 12px;
        color: var(--gray);
        line-height: 25px;
    }
    .content {
        font-size: 14px;
        margin-bottom: 10px;
    }
`;

export default NotificationCard;
