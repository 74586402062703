import styled from 'styled-components';
import { NotificationsController } from './NotificationsController';
import { UserSettingsController } from './UserSettingsController';

export const TopBar = () => {
    const role = localStorage.getItem('role');
    const isAdmin = role === 'admin';
    return (
        <TopBarContainer>
            {!isAdmin ? <NotificationsController /> : null}
            <UserSettingsController />
        </TopBarContainer>
    );
};

const TopBarContainer = styled.div`
    background: var(--body-gray);
    display: flex;
    align-items: center;
    gap: 2rem;
    background: var(--body-gray);
    justify-content: flex-end;
    position: relative;
    padding: 1.2rem 50px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    .user-icon,
    .bell-icon {
        font-size: 1.5rem;
    }
`;
