import fetch from '../fetch';
import routes from '../routes';
import { ObjectKeys } from '../../types/objectKeys';
import getCurrentLanguage from '../../functions/getCurrentLanguage';
import {
    GET_NotificationsRequest,
    GET_NotificationsResponse,
    POST_MarkAllAsReadRequest,
    POST_MarkAsReadRequest,
} from './types';

export default class Notifications {
    public createNotification(data: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.notifications.create,
            data: data,
        });
    }
    public createAndSendNotification(data: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.notifications.createAndSend,
            data: data,
        });
    }
    public getNotifications(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.notifications.getNotifications,
            params: params,
        });
    }
    public getUserNotifications(params: ObjectKeys): GET_NotificationsResponse {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.notifications.getUserNotifications,
            params: params,
        });
    }
    public getNotificationById(id: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.notifications.getNotificationById(id),
        });
    }
    public updateNotification(data: ObjectKeys, id: string): ObjectKeys {
        return fetch({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.notifications.update(id),
            data: data,
        });
    }
    public sendNotification(id: string): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.notifications.send(id),
        });
    }
    public deleteNotification(id: string): ObjectKeys {
        return fetch({
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.notifications.delete(id),
        });
    }
    public markAllAsRead(params: POST_MarkAllAsReadRequest): Promise<ObjectKeys> {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.notifications.markAllAsRead,
            params,
        });
    }
    public markAsRead(params: POST_MarkAsReadRequest): Promise<ObjectKeys> {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.notifications.markAsRead,
            params,
        });
    }
    public getUnreadCountNotf(): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.notifications.getUnreadCound,
        });
    }
}
