import { FunctionComponent, useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams, useBlocker } from 'react-router-dom';
import moment from 'moment';
import { some, isEmpty } from 'lodash';
import useLanguageStore from '../../../../store/language';
import useAgencyStore from '../../../../store/agency';
import useTranslations from '../../../../hooks/useTranslation';
import useWindowSize from '../../../../hooks/useResize';
import { getAccessToken } from '../../../../functions/auth';
import { ObjectKeys } from '../../../../types/objectKeys';
import { SendDocumentFormData } from '../../../../types/communication/document';
import { generateQrCode } from '../../../../communication/qr';
import communication from '../../../../communication';
import Loader from '../../../../components/loader';
import Sidebar from '../../../../components/sideModal';
import Modal from '../../../../components/modal';
import Success from '../../../../components/successModal';
import ErrorModal from '../../../../components/errorModal';
import ChangeModal from '../../../../components/changeModal';
import InvoiceClients from '../../../../components/invoiceClients';
import AddClientModal from '../../clients/addClientModal';
import PreviewInvoiceModalPlain from '../../common/previewInvoiceModal';
import PreviewInvoiceModalArtistic from '../../common/previewInvoiceModal2';
import PreviewInvoiceModalBusiness from '../../common/previewInvoiceModal3';
import PreviewInvoiceModalInvoicePro from '../../common/previewInvoiceModal4';
import useUserStore from '../../../../store/user';
import { Invoice } from '../../common/style';
import InvoiceEdit from '../invoiceEdit';
import MobileFooter from '../../common/footer/mobileFooter';
import WebFooter from '../../common/footer/webFooter';
import MobileHeader from '../../common/header/mobileHeader';
import WebHeader from '../../common/header/webHeader';
import EditClientModal from '../../clients/editClientModal';
import SendModal from '../sendModal';
import { countTotalProforma } from '../../common/countTotal';
import InvoiceForeignEdit from '../invoiceForeignEdit';
import getAllClients from '../../clients/getAllClients';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Warning from '../../../../components/successModal';
import WarningClientCard from '../../clients/warningCard';
import useUnitsStore from '../../../../store/measurementUnits';
import ConfirmModal from '../../../../components/confirmModal';
import useAllBanksQuery from '../../../../react-query/useAllBanksQuery';
import { validateIbanSwift } from '../../common/ibanSwiftValidate';

const CopyProforma: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { pathname } = useLocation();
    const { agency } = useAgencyStore();
    const size = useWindowSize();
    const navigate = useNavigate();
    const proformaId = useParams().proformaId;
    const token = getAccessToken();
    const { userInfo } = useUserStore();
    const preferredTemplate = userInfo?.preferred_template?.data.display_name;

    const { units } = useUnitsStore();

    const [previewModal, setPreviewModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [addClientModal, setAddClientModal] = useState(false);
    const [editClientModal, setEditClientModal] = useState(false);
    const [deleteClientModal, setDeleteClientModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [deleteInvoiceModal, setDeleteInvoiceModal] = useState(false);
    const [changeHappened, setChangeHappened] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [clientChanged, setClientChanged] = useState(0);
    const [clientEmail, setClientEmail] = useState('');

    const [warningModal, setWarningModal] = useState(false);
    const [clientsDuplicated, setClientsDuplicated] = useState<ObjectKeys>([]);

    const [oldProformaId, setOldProformaId] = useState('');

    const { data } = useAllBanksQuery();
    const [badSwiftOrIban, setBadSwiftOrIban] = useState<boolean>(false);
    const [badSwiftOrIbanModal, setBadSwiftOrIbanModal] = useState<boolean>(false);

    const swiftIbanOptions =
        agency.bankAccounts.data.length > 0
            ? agency.bankAccounts.data
                  .filter((account: ObjectKeys) => account.type === 'foreign')
                  .map((account: ObjectKeys) => ({
                      value: account,
                  }))
            : [];

    const [qrCode, setQrCode] = useState('');
    const [clickedClient, setClickedClient] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const [newCreatedClient, setNewCreatedClient] = useState<ObjectKeys>({});
    const [clients, setClients] = useState<ObjectKeys>([]);
    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });
    const [proformaData, setProformaData] = useState<ObjectKeys>({
        client: { data: {} },
        comment: '',
        currency: 'RSD',
        iban: '',
        paid_date: '',
        paid_value: false,
        prepaid_invoice_ids: [],
        rate: 1,
        seal_required: false,
        swift: '',
        trading_place: '',
    });

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    useEffect(() => {
        setLoading(true);
        if (proformaId) {
            communication.getProformaById(proformaId).then((res: ObjectKeys) => {
                setProformaData({
                    ...res?.data?.data,
                    quotation_number: '',
                    id: '',
                });
                setOldProformaId(proformaId);
                setClientEmail(res?.data?.data?.client?.data?.email);
                const newUnits = res?.data.data.services
                    .map((item: ObjectKeys) => item.pivot.measurement_unit)
                    .filter((unit: any) => !units.includes(unit));

                useUnitsStore.setState({ units: [...units, ...newUnits] });
                setLoading(false);
                agency &&
                    res?.data?.data?.type !== 'foreign' &&
                    handleGenerateQRCode(
                        agency,
                        res?.data?.data?.client?.data?.company_name,
                        res?.data?.data?.value,
                        res?.data?.data?.id,
                        res?.data?.data?.bank_account,
                        res?.data?.data?.currency,
                        true,
                        res?.data?.data?.quotation_number,
                    );
            });
        }
    }, [proformaId]);

    useEffect(() => {
        if (!isEmpty(newCreatedClient)) {
            setProformaData((prev: ObjectKeys) => {
                const newProformaData = { ...prev };
                newProformaData.client_id = newCreatedClient && newCreatedClient?.id;
                newProformaData.client.data = newCreatedClient;
                return newProformaData;
            });
        }
    }, [newCreatedClient]);

    useEffect(() => {
        setChangeHappened(true);
    }, []);

    const handleClientDelete = async (): Promise<void> => {
        setLoading(true);
        setClientEmail('');
        await communication
            .deleteClient(clickedClient)
            .then((res: ObjectKeys) => {
                if (res) {
                    getAllClients(setClients, agency?.user_id, proformaData.type === 'national' ? 0 : 1);
                    setDeleteClientModal(false);
                    setLoading(false);
                    toast.success(t('pages.clients.archiveModal.message').text);
                }
                setProformaData((prev: ObjectKeys) => ({
                    ...prev,
                    client: { data: {} },
                }));
            })
            .catch((error: ObjectKeys) => {
                setLoading(false);
                if (error) {
                    toast.error(error.response.data.message);
                }
            });
    };

    const handleProformaDelete = async (): Promise<void> => {
        setDeleteInvoiceModal(false);
        setLoading(true);
        communication
            .deleteProforma(oldProformaId)
            .then((res: ObjectKeys) => {
                if (res) {
                    setLoading(false);
                    navigate('/proforma');
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setErrorMessage(error.response.data.message);
                    setOpenErrorModal(true);
                    setLoading(false);
                }
            });
    };

    //QR code
    const handleGenerateQRCode = (
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        proformaiD: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
        proformaNumber: string | null,
    ): void => {
        try {
            generateQrCode(
                agencyData.name,
                clientData,
                amount,
                proformaiD,
                bankAccount,
                currency,
                convert,
                proformaNumber ? proformaNumber : null,
                '221',
            ).then((response: ObjectKeys | any) => {
                if (response.i) setQrCode(response.i);
                else setQrCode('');
            });
        } catch (error: any) {
            setQrCode('');
            setErrorMessage(error.response.data.message);
            setOpenErrorModal(true);
        }
    };

    const handleSelectedClient = (client: ObjectKeys): void => {
        setClientChanged(clientChanged + 1);
        setChangeHappened(true);
        setClientEmail(client?.email);
        setProformaData((prev: ObjectKeys) => {
            const newProformaData = { ...prev };
            newProformaData.client_id = client?.id;
            newProformaData.client.data = client;
            return newProformaData;
        });
        agency &&
            handleGenerateQRCode(
                agency,
                client.company_name,
                proformaData.value,
                oldProformaId,
                proformaData.bank_account,
                proformaData.currency,
                true,
                null,
            );
    };

    const proformaType = proformaData.type === 'national' ? 'domestic' : 'foreign';

    const isProformaValid = (): boolean => {
        if (!proformaData.services || proformaData.services.length === 0) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingService').text);
            return false;
        }

        if (
            some(
                proformaData.services,
                (s) =>
                    Math.ceil(Number(s.pivot.quantity.replace(',', '.'))) === 0 ||
                    Math.ceil(Number(s.pivot.price_per_unit.replace(',', '.'))) === 0 ||
                    s.pivot.measurement_unit === '',
            )
        ) {
            setModalMessage(t('pages.editInvoice.invalidInvoice').text);
            setSuccess(true);
            return false;
        }

        if (!proformaData.client_id || Object.keys(proformaData.client.data).length === 0) {
            setModalMessage(t('pages.editInvoice.missingClient').text);
            setSuccess(true);
            return false;
        }

        if (proformaData.trading_place === '') {
            setModalMessage(t('pages.editInvoice.missingTradingPlace').text);
            setSuccess(true);
            return false;
        }
        if (
            !validateIbanSwift(
                proformaData.iban,
                proformaData.swift,
                proformaData.bank_account_id,
                returnBankCodeFromId(proformaData.bank_id) === '000' || proformaType !== 'foreign',
                agency,
                swiftIbanOptions,
                data,
            )
        ) {
            setBadSwiftOrIban(true);
            setBadSwiftOrIbanModal(true);
            setChangeHappened(false);
            return false;
        }

        return true;
    };

    const handleAddNewProforma = async (): Promise<any> => {
        if (!isProformaValid()) return;
        try {
            setChangeHappened(false);
            setLoading(true);
            const newProformaData = { ...proformaData };

            newProformaData.services = newProformaData.services.map((service: ObjectKeys, i: number) => ({
                index: i,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: service.pivot.quantity.replace(',', '.'),
                price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                discount: service.pivot.discount,
            }));
            newProformaData.trading_date = moment(newProformaData.trading_date).format('YYYY-MM-DD').toString();
            newProformaData.rate = Number(newProformaData.rate);
            newProformaData.paid_date = moment(
                newProformaData.paid_date ? newProformaData.paid_date : new Date('1970-01-01'),
            )
                .format('YYYY-MM-DD')
                .toString();
            newProformaData.quotation_date = moment(newProformaData.quotation_date).format('YYYY-MM-DD').toString();
            newProformaData.custom_number = false;
            newProformaData.is_custom = false;
            newProformaData.custom_text = false;
            newProformaData.invoice_per_owner = true;
            newProformaData.auto_increment = true;
            newProformaData.cancel_number = false;
            newProformaData.cancelled = false;
            newProformaData.currency = proformaData.currency;
            newProformaData.status = 'open';
            newProformaData.user_id = agency?.user_id;
            newProformaData.trading_place = proformaData?.trading_place;
            newProformaData.value = Number(countTotalProforma(newProformaData.services).replace(',', '.'));
            newProformaData.client_id = proformaData?.client?.data?.id;
            delete newProformaData.total_for_payment_in_rsd, newProformaData.total_for_payment;
            if (proformaType === 'domestic') {
                newProformaData.bank_account = proformaData.bank_account;
            } else {
                newProformaData.swift = agency?.swift;
                newProformaData.iban = agency?.iban;
            }

            communication
                .newProforma(newProformaData, proformaType)
                .then((res: ObjectKeys) => {
                    if (res) {
                        setLoading(false);
                        navigate('/proforma');
                        return {
                            id: res?.data?.data?.id,
                            number: res?.data?.data?.quotation_number,
                            agency: res?.data?.data?.agency?.data.full_name,
                            client: res?.data?.data?.client.data.company_name,
                            amount: res?.data?.data?.value_in_rsd,
                            account: res?.data?.data?.bank_account,
                            currency: res?.data?.data?.currency,
                            foreign: res?.data?.data?.type === 'foreign' ? true : false,
                        };
                    }
                })
                .catch((error: ObjectKeys) => {
                    setErrorMessage(
                        error.response.data.errors.length > 0
                            ? error.response.data.errors.client_id[0]
                            : error.response.data.message,
                    );
                    setOpenErrorModal(true);
                    setLoading(false);
                });
        } catch (e: any) {
            setErrorMessage(
                e.response.data.errors.length > 0 ? e.response.data.errors.client_id[0] : e.response.data.message,
            );
            setOpenErrorModal(true);
            setLoading(false);
        }
    };
    //update proforma
    const handleUpdateProforma = async (): Promise<any> => {
        setChangeHappened(false);
        if (!isProformaValid()) return;
        try {
            setChangeHappened(false);
            setLoading(true);
            const updateProforma = { ...proformaData };

            updateProforma.services = updateProforma.services.map((service: ObjectKeys, i: number) => ({
                index: i,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: service.pivot.quantity.replace(',', '.'),
                price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                discount: service.pivot.discount,
            }));
            updateProforma.value = Number(countTotalProforma(updateProforma.services).replace(',', '.'));
            updateProforma.trading_date = moment(updateProforma.trading_date).format('YYYY-MM-DD').toString();
            updateProforma.rate = Number(updateProforma.rate);
            updateProforma.paid_date = moment(
                updateProforma.paid_date ? updateProforma.paid_date : new Date('1970-01-01'),
            )
                .format('YYYY-MM-DD')
                .toString();
            updateProforma.quotation_date = moment(updateProforma.quotation_date).format('YYYY-MM-DD').toString();
            updateProforma.custom_number = false;
            updateProforma.is_custom = false;
            updateProforma.custom_text = false;
            updateProforma.invoice_per_owner = true;
            updateProforma.auto_increment = true;
            updateProforma.cancel_number = false;
            updateProforma.cancelled = false;
            delete updateProforma.quotation_number;
            const res = await communication.editProforma(oldProformaId, updateProforma);

            if (res.status === 200) {
                setLoading(false);
                setChangeHappened(false);
                return res?.data?.data;
            }
        } catch (error: any) {
            setErrorMessage(error.response.data.message);
            setOpenErrorModal(true);
            setLoading(false);
        }
    };
    //Proforma Download
    const handleDownloadProforma = async (): Promise<void> => {
        const newProformaId = await handleAddNewProforma();
        if (!newProformaId) return;
        try {
            generateQrCode(
                agency.name,
                proformaData.client ? proformaData.client.data.company_name : {},
                proformaData.value_in_rsd ? proformaData.value_in_rsd.toString() : '0',
                newProformaId.number ? newProformaId.number : '',
                proformaData.bank_account,
                proformaData.currency,
                true,
                newProformaId.number ? `00${newProformaId.number?.replace('/', '-')}` : null,
                '221',
            )
                .then(async (res: ObjectKeys | any) => {
                    if (res) {
                        if (newProformaId) {
                            communication.downloadProforma(
                                newProformaId.id,
                                token,
                                proformaData.quotation_number,
                                proformaData.currency !== 'RSD',
                                res?.i ? res.i : '',
                            );
                        }
                    }
                })
                .catch((error: ObjectKeys) => {
                    if (newProformaId) {
                        communication.downloadProforma(
                            newProformaId.id,
                            token,
                            proformaData.quotation_number,
                            proformaData.currency !== 'RSD',
                        );
                    }
                    console.error(error);
                });
        } catch (error: any) {
            setErrorMessage(error.response.data.message);
            setOpenErrorModal(true);
        }
    };

    // Create invoice from proforma
    const handleCreateInvoiceFromProforma = async (): Promise<void> => {
        const proformaDataToSend = await handleUpdateProforma();
        if (!proformaDataToSend) return;
        const proformaServices = proformaDataToSend.services.map((service: ObjectKeys, index: number) => {
            return {
                index: index,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: service.pivot.quantity,
                price_per_unit: service.pivot.price_per_unit,
            };
        });
        proformaDataToSend['invoice_date'] = proformaDataToSend.quotation_date;
        proformaDataToSend['services'] = proformaServices;
        proformaDataToSend['status'] = 'sent';
        proformaDataToSend['is_prepaid'] = 'false';
        navigate(`/invoices/copy/${oldProformaId}/quotation`);
    };

    // Create advance from proforma
    const handleCreateAdvanceFromProforma = async (): Promise<void> => {
        const proformaDataToSend = await handleUpdateProforma();
        if (!proformaDataToSend) return;
        const proformaServices = proformaData.services.map((service: ObjectKeys, index: number) => {
            return {
                index: index,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: service.pivot.quantity,
                price_per_unit: service.pivot.price_per_unit,
            };
        });
        proformaDataToSend['invoice_date'] = proformaDataToSend.quotation_date;
        proformaDataToSend['services'] = proformaServices;
        proformaDataToSend['status'] = 'sent';
        proformaDataToSend['is_prepaid'] = 'false';
        navigate(`/advance-invoices/copy/${oldProformaId}/quotation`);
    };

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => changeHappened && currentLocation.pathname !== nextLocation.pathname,
    );

    const returnBankCodeFromId = (bankId: string): string => {
        if (data) {
            const bank = data.find((banka: { id: string }) => banka.id === bankId);
            if (bank && bank.code) {
                return bank.code;
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

    return (
        <>
            {loading && <Loader />}
            <ToastContainer />
            <Modal modalVisible={blocker.state === 'blocked'} closeModal={() => blocker.reset && blocker.reset()}>
                <ConfirmModal
                    message={t('warnings.youHaveUnsavedChanges').text}
                    close={() => blocker.reset && blocker.reset()}
                    action={() => blocker.proceed && blocker.proceed()}
                />
            </Modal>
            {warningModal && (
                <Modal modalVisible={warningModal} closeModal={() => setWarningModal(false)}>
                    <Warning
                        close={() => {
                            setWarningModal(false);
                        }}
                    >
                        <WarningClientCard clients={clientsDuplicated} />
                    </Warning>
                </Modal>
            )}
            {badSwiftOrIban && badSwiftOrIbanModal && (
                <Modal
                    modalVisible={true}
                    preventOutsideClickClose={false}
                    closeModal={() => {
                        setBadSwiftOrIbanModal(false);
                    }}
                >
                    <ErrorModal
                        t={t}
                        setOpenModal={() => {
                            navigate('/agency/bank-accounts?=foreign');
                            setBadSwiftOrIbanModal(false);
                        }}
                        errorMessage={t('pages.invoices.bad_iban_or_swift').text}
                        buttonName={t('pages.invoices.enter').text}
                    />
                </Modal>
            )}
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={modalMessage} />
                </Modal>
            )}
            {addClientModal && (
                <Sidebar close={() => setAddClientModal(false)}>
                    <AddClientModal
                        t={t}
                        clickedClient={proformaData.type === 'national' ? 'domestic' : 'foreign'}
                        userId={agency?.user_id}
                        setAddClientModal={setAddClientModal}
                        setClients={setClients}
                        tabIndex={proformaData.type === 'national' ? 0 : 1}
                        setNewCreatedClient={setNewCreatedClient}
                        isDuplicate={(duplicated: Array<ObjectKeys>) => {
                            setWarningModal(true);
                            setClientsDuplicated(duplicated);
                        }}
                    />
                </Sidebar>
            )}
            {editClientModal && (
                <Sidebar close={() => setEditClientModal(false)}>
                    <EditClientModal
                        t={t}
                        clickedClient={clickedClient}
                        userId={agency?.user_id}
                        setEditClientModal={setEditClientModal}
                        setClients={setClients}
                        tabIndex={proformaData.type === 'national' ? 0 : 1}
                    />
                </Sidebar>
            )}
            {deleteClientModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteClientModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={() => {
                            handleClientDelete();
                            setDeleteClientModal(false);
                        }}
                        close={() => {
                            setDeleteClientModal(false);
                        }}
                        message={`${t('pages.clients.archiveModal.title').text}\n${
                            t('pages.clients.archiveModal.description').text
                        }`}
                    />
                </Modal>
            )}
            {deleteInvoiceModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteInvoiceModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={() => {
                            setChangeHappened(false);
                            handleProformaDelete();
                        }}
                        close={() => {
                            setDeleteInvoiceModal(false);
                        }}
                        message={t('pages.newProforma.deleteProforma').text}
                    />
                </Modal>
            )}
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendModal
                        close={() => setSendModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        id={oldProformaId}
                        success={() => {
                            setSendModal(false);
                            navigate('/proforma');
                        }}
                        loading={(e: boolean) => setLoading(e)}
                        clientEmail={clientEmail}
                        setProforma={setProformaData}
                        qrCode={qrCode}
                        modalVisible={sendModal}
                        handleAddNewProforma={handleAddNewProforma}
                    />
                </Sidebar>
            )}
            {previewModal && (
                <Modal
                    width="700px"
                    className="preview-modal"
                    modalVisible={true}
                    hasCloseButton={true}
                    closeModal={() => {
                        setPreviewModal(false);
                    }}
                >
                    {preferredTemplate === 'Plain' && (
                        <PreviewInvoiceModalPlain
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaData.type === 'national' ? 'domestic' : 'foreign'}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'Artistic' && (
                        <PreviewInvoiceModalArtistic
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaData.type === 'national' ? 'domestic' : 'foreign'}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'Business' && (
                        <PreviewInvoiceModalBusiness
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaData.type === 'national' ? 'domestic' : 'foreign'}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'InvoicePro' && (
                        <PreviewInvoiceModalInvoicePro
                            agency={agency}
                            invoice={proformaData}
                            title="proforma"
                            type={proformaData.type === 'national' ? 'domestic' : 'foreign'}
                            note={proformaData.comment}
                            qrCode={qrCode}
                        />
                    )}
                </Modal>
            )}
            <div className="new-proforma page">
                {isMobileWidth ? (
                    <MobileHeader
                        t={t}
                        handleUpdateInvoice={handleAddNewProforma}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={() => {
                            isProformaValid() && setPreviewModal(true);
                        }}
                        setSendModal={() => {
                            isProformaValid() && setSendModal(true);
                        }}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleAddNewProforma}
                        invoice={proformaData}
                        title={'proforma'}
                        handleDownloadInvoice={handleDownloadProforma}
                        handleCreateInvoiceFromProforma={handleCreateInvoiceFromProforma}
                        handleCreateAdvanceFromProforma={handleCreateAdvanceFromProforma}
                        newInvoice={true}
                    />
                ) : (
                    <WebHeader
                        t={t}
                        handleUpdateInvoice={handleAddNewProforma}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={() => {
                            isProformaValid() && setPreviewModal(true);
                        }}
                        setSendModal={() => {
                            isProformaValid() && setSendModal(true);
                        }}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleAddNewProforma}
                        invoice={proformaData}
                        title={'proforma'}
                        handleDownloadInvoice={handleDownloadProforma}
                        handleCreateInvoiceFromProforma={handleCreateInvoiceFromProforma}
                        handleCreateAdvanceFromProforma={handleCreateAdvanceFromProforma}
                        newInvoice={true}
                    />
                )}
                <InvoiceClients
                    t={t}
                    setClients={setClients}
                    clients={clients}
                    setAddClientModal={setAddClientModal}
                    agency={agency}
                    handleSelectedClient={handleSelectedClient}
                    addClientModal={addClientModal}
                    deleteClientModal={deleteClientModal}
                    editClientModal={editClientModal}
                    setClickedClient={setClickedClient}
                    setEditClientModal={setEditClientModal}
                    setDeleteClientModal={setDeleteClientModal}
                    invoice={proformaData}
                    type={
                        proformaData?.type === 'national'
                            ? 'domestic'
                            : proformaData?.type === 'foreign'
                            ? 'foreign'
                            : ''
                    }
                    loading={(isLoading: boolean) => setLoading(isLoading)}
                />
                {agency && proformaData && (
                    <Invoice>
                        {proformaData?.type === 'national' ? (
                            <InvoiceEdit
                                name="proforma"
                                formData={proformaData}
                                setFormData={setProformaData}
                                agency={agency}
                                invoice={proformaData}
                                handleTotal={(sum: ObjectKeys) => {
                                    setProformaData({
                                        ...proformaData,
                                        value: sum,
                                        value_in_rsd: sum,
                                        total_for_payment: sum,
                                        total_for_payment_in_rsd: sum,
                                    });
                                }}
                                isMobileWidth={isMobileWidth}
                                newInvoice={true}
                                type={'domestic'}
                                setLoading={setLoading}
                                handleQrCode={(qr: string) => setQrCode(qr)}
                                // qrCode={qrCode}
                            />
                        ) : (
                            <InvoiceForeignEdit
                                name="proforma"
                                formData={proformaData}
                                setFormData={setProformaData}
                                agency={agency}
                                invoice={proformaData}
                                handleTotal={(sum: ObjectKeys) => {
                                    setProformaData({
                                        ...proformaData,
                                        value: sum,
                                        total_for_payment: sum,
                                    });
                                }}
                                isMobileWidth={isMobileWidth}
                                newInvoice={true}
                                type={'foreign'}
                                setLoading={setLoading}
                                qrCode={qrCode}
                                clientChanged={clientChanged}
                                handleChangeHappend={(change: boolean) => {
                                    setChangeHappened(change);
                                }}
                            />
                        )}
                    </Invoice>
                )}
                {isMobileWidth ? (
                    <MobileFooter
                        handleUpdateInvoice={handleAddNewProforma}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setPreviewModal={() => {
                            isProformaValid() && setPreviewModal(true);
                        }}
                        setSendModal={() => {
                            isProformaValid() && setSendModal(true);
                        }}
                        handleDownloadInvoice={handleDownloadProforma}
                        handleCreateInvoiceFromProforma={handleCreateInvoiceFromProforma}
                        handleCreateAdvanceFromProforma={handleCreateAdvanceFromProforma}
                        name="proforma"
                        invoice={proformaData}
                    />
                ) : (
                    <WebFooter
                        t={t}
                        handleUpdateInvoice={handleAddNewProforma}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setPreviewModal={() => {
                            isProformaValid() && setPreviewModal(true);
                        }}
                        setSendModal={() => {
                            isProformaValid() && setSendModal(true);
                        }}
                        handleDownloadInvoice={handleDownloadProforma}
                        handleCreateInvoiceFromProforma={handleCreateInvoiceFromProforma}
                        handleCreateAdvanceFromProforma={handleCreateAdvanceFromProforma}
                        name="proforma"
                        invoice={proformaData}
                    />
                )}
            </div>
        </>
    );
};
export default CopyProforma;
