import { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSave, faUndo, faTrash } from '@fortawesome/free-solid-svg-icons';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import Modal from '../../../../components/modal';
import ConfirmModal from '../../../../components/confirmModal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Input, { InputComponentType } from '../../../../components/input';

interface FormData {
    title_of_alert_rs: string;
    title_of_alert_en: string;
    title_of_alert_ru: string;
    description_rs: string;
    description_en: string;
    description_ru: string;
}

interface NotificationFormProps {
    t: Function;
    onSend: Function;
    onSave: Function;
    onEdit: Function;
    type: 'create' | 'edit';
    editData: FormData | null;
}

const initialData = {
    title_of_alert_en: '',
    title_of_alert_rs: '',
    title_of_alert_ru: '',
    description_en: '<p></p>',
    description_rs: '<p></p>',
    description_ru: '<p></p>',
};

const NotificationForm: FunctionComponent<NotificationFormProps> = ({
    onSend,
    onSave,
    onEdit,
    type,
    t,
    editData,
}: NotificationFormProps) => {
    const schema = z.object({
        title_of_alert_rs: z.string().min(1, { message: t('pages.validationMessages.requiredField').text }),
        title_of_alert_en: z.string().min(1, { message: t('pages.validationMessages.requiredField').text }),
        title_of_alert_ru: z.string().min(1, { message: t('pages.validationMessages.requiredField').text }),
        description_rs: z.string().min(1, { message: t('pages.validationMessages.requiredField').text }),
        description_en: z.string().min(1, { message: t('pages.validationMessages.requiredField').text }),
        description_ru: z.string().min(1, { message: t('pages.validationMessages.requiredField').text }),
    });

    const { control, handleSubmit, reset } = useForm<FormData>({
        resolver: zodResolver(schema),
        defaultValues: type === 'edit' && editData ? editData : initialData,
    });

    const resetForm = (): void => {
        reset(initialData);
    };

    const [isSendModalVisible, setIsSendModalVisible] = useState(false);
    const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);

    const [modalMessage, setModalMessage] = useState('');
    const [showUndo, setShowUndo] = useState(false);

    const [countdownInterval, setCountdownInterval] = useState<NodeJS.Timeout | null>(null);
    const [countdown, setCountdown] = useState<number>(10);

    const handleSave = (): void => {
        setIsSaveModalVisible(true);
        setModalMessage(
            type === 'create'
                ? 'Da li ste sigurni da želite da dodate notifikaciju?'
                : 'Da li ste sigurni da želite da izmenite notifikaciju?',
        );
    };

    const confirmSave = async (formData: FormData): Promise<void> => {
        onSave(formData);
        setIsSaveModalVisible(false);
    };

    const confirmUpdate = async (formData: FormData): Promise<void> => {
        onEdit(formData);
        setIsSaveModalVisible(false);
    };

    const handleSend = (): void => {
        setIsSendModalVisible(true);
        setModalMessage('Da li ste sigurni da želite da pošaljete notifikaciju?');
    };

    const confirmSend = (formData: FormData): void => {
        setShowUndo(true);
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown - 1 === 0) {
                    clearInterval(timer);
                    finalSend(formData);
                    return 10;
                } else {
                    return prevCountdown - 1;
                }
            });
        }, 1000);

        setCountdownInterval(timer);
        setIsSendModalVisible(false);
    };

    const finalSend = async (formData: FormData): Promise<void> => {
        onSend(formData);
        // () => reset(initialData);
        setShowUndo(false);
    };

    const handleUndo = (): void => {
        if (countdownInterval) {
            clearInterval(countdownInterval);
            setCountdownInterval(null);
        }
        setShowUndo(false);
        setCountdown(10);
    };

    useEffect(() => {
        return () => {
            if (countdownInterval) {
                clearInterval(countdownInterval);
            }
        };
    }, [countdownInterval]);

    useEffect(() => {
        if (type === 'edit' && editData) {
            reset(editData);
        }
    }, [type, editData, reset]);

    const editorConfig = {
        toolbar: ['bold', 'italic', 'link', 'heading', '|', 'undo', 'redo'],
        link: {
            addTargetToExternalLinks: true,
        },
    };

    return (
        <>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <div className="type">
                    <h2>{type === 'create' ? 'Dodaj notifikaciju' : 'Izmeni notifikaciju'}</h2>
                </div>
                <div>
                    <div>
                        <p className="bold">Naziv notifikacije:</p>
                        <Controller
                            name="title_of_alert_rs"
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <div>
                                    <label>Srpski</label>
                                    <Input
                                        onChange={onChange}
                                        value={value}
                                        name={name}
                                        type={InputComponentType.Text}
                                        maxLength={50}
                                        placeholder="Naslov Srpski"
                                        className={'input'}
                                        validation={error?.message}
                                        isDisabled={showUndo}
                                    />
                                </div>
                            )}
                        />
                        <Controller
                            name="title_of_alert_en"
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <div>
                                    <label>Engleski</label>
                                    <Input
                                        onChange={onChange}
                                        value={value}
                                        name={name}
                                        type={InputComponentType.Text}
                                        maxLength={50}
                                        placeholder="Naslov Engleski"
                                        className={'input'}
                                        validation={error?.message}
                                        isDisabled={showUndo}
                                    />
                                </div>
                            )}
                        />
                        <Controller
                            name="title_of_alert_ru"
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <div>
                                    <label>Ruski</label>
                                    <Input
                                        onChange={onChange}
                                        value={value}
                                        name={name}
                                        type={InputComponentType.Text}
                                        maxLength={50}
                                        placeholder="Naslov Ruski"
                                        className={'input'}
                                        validation={error?.message}
                                        isDisabled={showUndo}
                                    />
                                </div>
                            )}
                        />
                    </div>
                    <div>
                        <p className="bold">Poruka:</p>
                        <p className="warning">
                            NAPOMENA: Linkove unositi u formatu{' '}
                            <span className="link">https://pausal.rs/skola/izdavanje-faktura/</span>
                        </p>
                        <Controller
                            name="description_rs"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div>
                                    <label>Srpski</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{ ...editorConfig }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            onChange(data);
                                        }}
                                        data={value}
                                        disabled={showUndo}
                                    />
                                    {error && <span className="error">{error.message}</span>}
                                </div>
                            )}
                        />
                        <Controller
                            name="description_en"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div>
                                    <label>Engleski</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={editorConfig}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            onChange(data);
                                        }}
                                        data={value}
                                        disabled={showUndo}
                                    />
                                    {error && <span className="error">{error.message}</span>}
                                </div>
                            )}
                        />
                        <Controller
                            name="description_ru"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div>
                                    <label>Ruski</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={editorConfig}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            onChange(data);
                                        }}
                                        data={value}
                                        disabled={showUndo}
                                    />
                                    {error && <span className="error">{error.message}</span>}
                                </div>
                            )}
                        />
                    </div>
                </div>
                <div className="button">
                    {!showUndo ? (
                        <div className="button-2">
                            {type === 'create' ? (
                                <Button
                                    color={colors.green}
                                    size={'60%'}
                                    variant={ButtonVariant.solid}
                                    type="button"
                                    disabled={showUndo}
                                    onClick={handleSubmit(handleSave)}
                                >
                                    <FontAwesomeIcon icon={faSave} className="fa-icon-save" />
                                    Sačuvaj
                                </Button>
                            ) : (
                                <Button
                                    color={colors.green}
                                    size={'60%'}
                                    variant={ButtonVariant.solid}
                                    type="button"
                                    disabled={showUndo}
                                    onClick={handleSubmit(handleSave)}
                                >
                                    <FontAwesomeIcon icon={faSave} className="fa-icon-save" />
                                    Sačuvaj izmene
                                </Button>
                            )}

                            <Button
                                color={colors.blue}
                                size={'60%'}
                                variant={ButtonVariant.solid}
                                type="button"
                                disabled={showUndo}
                                onClick={handleSubmit(handleSend)}
                            >
                                <FontAwesomeIcon icon={faEnvelope} className="fa-icon-save" />
                                Pošalji
                            </Button>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.danger}
                                size={'40%'}
                                onClick={resetForm}
                            >
                                <FontAwesomeIcon icon={faTrash} className="fa-icon-save" />
                                Obriši unos
                            </Button>
                        </div>
                    ) : (
                        <ButtonContainer>
                            <Button
                                color={colors.purple}
                                onClick={handleUndo}
                                variant={ButtonVariant.solid}
                                type="button"
                                size={'100%'}
                            >
                                <FontAwesomeIcon icon={faUndo} className="fa-icon-save" />
                                {`Prekini slanje ${countdown}`}
                            </Button>
                        </ButtonContainer>
                    )}
                </div>
            </Form>

            {isSendModalVisible && !isSaveModalVisible && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={isSendModalVisible}
                    closeModal={() => setIsSendModalVisible(false)}
                >
                    <ConfirmModal
                        action={handleSubmit(confirmSend)}
                        close={() => setIsSendModalVisible(false)}
                        active={isSendModalVisible}
                        message={modalMessage}
                    />
                </Modal>
            )}

            {isSaveModalVisible && !isSendModalVisible && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={isSaveModalVisible}
                    closeModal={() => setIsSaveModalVisible(false)}
                >
                    <ConfirmModal
                        action={type === 'create' ? handleSubmit(confirmSave) : handleSubmit(confirmUpdate)}
                        close={() => setIsSaveModalVisible(false)}
                        active={isSaveModalVisible}
                        message={modalMessage}
                    />
                </Modal>
            )}
        </>
    );
};

const Form = styled.form`
    border: 2px solid var(--purple);
    margin-top: 20px;
    padding: 20px;
    opacity: 1;
    max-height: 100%;
    min-height: 690px;
    .bold {
        font-weight: bold;
        margin-bottom: 20px;
    }
    label {
        display: block;
        margin-bottom: 10px;
    }
    .button {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
    }
    .button-2 {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    .fa-icon-save {
        margin-right: 10px;
        color: var(--white);
    }
    .error {
        color: var(--danger);
        font-size: 14px;
        margin-top: 10px;
    }
    @keyframes blink {
        50% {
            opacity: 0;
        }
    }
    .type {
        margin-bottom: 20px;
        h2 {
            font-size: 20px;
            font-weight: bold;
            color: var(--purple);
        }
        text-align: center;
    }
    .warning {
        color: var(--danger);
        font-size: 14px;
        margin-bottom: 20px;
    }
    .link {
        color: var(--purple);
    }
`;

const ButtonContainer = styled.div`
    width: 100%;
`;

export default NotificationForm;
