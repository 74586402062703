import Checkbox from '../../components/checkbox';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NotificationItem } from '../../components/notifications/NotificationItem';
import { NotificationItemSkeleton } from '../../components/notifications/NotificationSkeleton';
import Input, { InputComponentType } from '../../components/input';
import Pagination from '../..//components/pagination';
import { useNotificationsQuery } from '../../react-query/useNotificationsQuery';
import { useMarkAsRead } from './useMarkAsRead';
import { ObjectKeys } from '../../types/objectKeys';

const Notifications = () => {
    const [searchDateFrom, setSearchDateFrom] = useState<Date | null>(null);
    const [searchDateTo, setSearchDateTo] = useState<Date | null>(null);
    const [isChecked, setIsChecked] = useState<'select-all' | 'select-unread' | null>(null);

    const [params, setParams] = useState<ObjectKeys>({
        page: 1,
        limit: 10,
        search: '',
        searchFields: '',
        searchJoin: 'and',
        orderBy: 'created_at',
        sortedBy: 'desc',
    });

    const { notifications, isLoading } = useNotificationsQuery(params);
    const [hasAnyNotReadChecked, setHasAnyNotReadChecked] = useState<boolean>(false);
    const { mutate: markAsRead } = useMarkAsRead(params);

    const handleMarkAsRead = async (): Promise<void> => {
        const checkedNotifications = notifications?.data?.filter((notification) => notification.checked);
        const checkedNotificationIds = checkedNotifications?.map((notification) => notification.id) || [];
        markAsRead(checkedNotificationIds);
    };

    const handleNotificationCheck = (id: string): void => {
        const checkedNotification = notifications?.data.find((notf) => notf.id === id);
        if (checkedNotification) {
            checkedNotification.checked = !checkedNotification.checked;
        }
        setHasAnyNotReadChecked(
            !!notifications?.data?.find((item) => item.checked && !item.alert_users.data[0].is_read),
        );
    };

    const handleCheck = (check: 'select-all' | 'select-unread' | null): void => {
        setIsChecked(check);
        if (check === 'select-all') {
            notifications?.data.forEach((notif) => {
                notif.checked = true;
            });
        } else if (check === 'select-unread') {
            notifications?.data.forEach((notif) => {
                notif.checked = !notif.alert_users.data[0].is_read;
            });
        } else {
            notifications?.data.forEach((notif) => {
                notif.checked = false;
            });
        }
        setHasAnyNotReadChecked(
            !!notifications?.data?.find((item) => item.checked && !item.alert_users.data[0].is_read),
        );
    };

    const formatDateFromForQuery = (dateString: Date | null): string => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear(); // Use local year
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Use local month
        const day = String(date.getDate()).padStart(2, '0'); // Use local day
        return `${year}-${month}-${day}`;
    };

    const formatDateToForQuery = (dateString: Date | null): string => {
        if (!dateString) return '';
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1); // Add one day
        const year = date.getFullYear(); // Use local year
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Use local month
        const day = String(date.getDate()).padStart(2, '0'); // Use local day
        return `${year}-${month}-${day}`;
    };

    const updateSearchParams = (fromDate: Date | null, toDate: Date | null): void => {
        if (fromDate && toDate) {
            setParams((prevParams) => ({
                ...prevParams,
                page: 1,
                search: `created_at:${formatDateFromForQuery(fromDate)},${formatDateToForQuery(toDate)}`,
                searchFields: 'created_at:between',
            }));
        } else if (!fromDate && !toDate) {
            setParams((prevParams) => ({
                ...prevParams,
                page: 1,
                search: '',
                searchFields: '',
            }));
        }
    };

    useEffect(() => {
        notifications?.data.forEach((notif) => (notif.checked = false));
        setHasAnyNotReadChecked(
            !!notifications?.data?.find((item) => item.checked && !item.alert_users.data[0].is_read),
        );
    }, [notifications]);

    return (
        <PageWrapper>
            <Header>
                <h1>Notifikacije</h1>
            </Header>
            <Filters>
                <p className="filters-title">LISTA NOTIFIKACIJA</p>
                <div className="date-pickers">
                    <div className="date-picker">
                        <label htmlFor="date-from">ZA PERIOD OD:</label>
                        <Input
                            type={InputComponentType.Date}
                            id="date-from"
                            date={searchDateFrom}
                            isClearable
                            onChange={(date: Date) => {
                                setSearchDateFrom(date);
                                updateSearchParams(date, searchDateTo);
                            }}
                            maxDate={new Date()}
                        />
                    </div>
                    <div className="date-picker">
                        <label htmlFor="date-to">DO:</label>
                        <Input
                            type={InputComponentType.Date}
                            id="date-to"
                            isClearable
                            date={searchDateTo}
                            onChange={(date: Date) => {
                                setSearchDateTo(date);
                                updateSearchParams(searchDateFrom, date);
                            }}
                            maxDate={new Date()}
                        />
                    </div>
                </div>
            </Filters>
            <CheckBoxes>
                <div className="checkbox-wrapper">
                    <Checkbox
                        label={'Izaberi sve'}
                        defaultChecked={isChecked === 'select-all'}
                        onChange={(value: boolean) => {
                            handleCheck(value ? 'select-all' : null);
                        }}
                    />
                    {hasAnyNotReadChecked && (
                        <button className="mark-as-read-button" onClick={handleMarkAsRead}>
                            Oznaci kao procitano
                        </button>
                    )}
                </div>
                <div className="checkbox-wrapper">
                    <Checkbox
                        label={'Izaberi neprocitane'}
                        defaultChecked={isChecked === 'select-unread'}
                        onChange={(value: boolean) => {
                            handleCheck(value ? 'select-unread' : null);
                        }}
                    />
                </div>
            </CheckBoxes>
            {isLoading ? (
                Array.from({ length: 10 }).map((_, index) => <NotificationItemSkeleton key={index} />)
            ) : (
                <>
                    <NotificationsWrapper>
                        {notifications?.data?.map((notification) => (
                            <NotificationItem
                                onCheck={handleNotificationCheck}
                                {...notification}
                                key={notification.id}
                                onClick={markAsRead}
                            />
                        ))}
                    </NotificationsWrapper>
                    {notifications && notifications.data.length > 0 && (
                        <Pagination
                            pageCount={notifications.meta?.pagination?.total_pages}
                            onPageChange={(e: ObjectKeys) => {
                                setParams({ ...params, page: e.selected + 1 });
                            }}
                            nextLabel={`${
                                notifications.meta?.pagination?.current_page ===
                                notifications.meta?.pagination?.total_pages
                                    ? ''
                                    : '>'
                            }`}
                            previousLabel={`${notifications.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                            breakLabel="..."
                            initialPage={notifications.meta?.pagination?.current_page - 1}
                        />
                    )}
                </>
            )}
        </PageWrapper>
    );
};

export default Notifications;

const PageWrapper = styled.div``;

const Header = styled.div`
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
`;

const Filters = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--purple);
    padding: 1.5rem 1.5rem;
    flex-wrap: wrap;
    gap: 2rem;
    .filters-title {
        color: var(--white);
        font-size: 1.3rem;
    }
    .date-pickers {
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;
        .date-picker {
            display: flex;
            align-items: center;
            gap: 1.5rem;
            .datepicker-input-wrapper {
                background: var(--white);
                border: 1px solid var(--border-color);
                margin-top: 5px;
                svg {
                    margin-right: 5px;
                }
            }
            label {
                color: var(--white);
            }
        }
    }
`;

const CheckBoxes = styled.div`
    width: 100%;
    background-color: var(--white);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .checkbox-wrapper {
        display: flex;
        justify-content: space-between;
    }
    .mark-as-read-button {
        display: flex;
        align-items: center;
        gap: 1rem;
        background-color: var(--purple);
        border: none;
        color: var(--white);
        cursor: pointer;
        padding: 0.5rem 1rem;
        &:disabled {
            opacity: 0.8;
            cursor: default;
        }
    }
`;

const NotificationsWrapper = styled.div`
    border-top: 1px solid var(--purple);
`;
