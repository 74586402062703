import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import logo from '../../../assets/pausal-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../global/colors';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { AccountExpires, AccountExpired } from '../web';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useChangeModalStore from '../../../store/changeModal';
import SelectLang from '../../selectLang';
import Nbs from '../nbs';
import { Link, Navigate, useLocation } from 'react-router-dom';
import Button, { ButtonVariant } from '../../button';
import Modal from '../../modal';
import Success from '../../successModal';
import AccountExpiration from '../accountExpiration';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import NavItem, { NavItemType } from '../navItem';
import { NotificationsController } from '../../../components/topBar/NotificationsController';
import { UserSettingsController } from '../../../components/topBar/UserSettingsController';

interface Props {
    navItems: NavItemType[];
    role: string | null;
    openSuggestionModal: Function;
    expires: string | undefined;
    isAccountActive: boolean;
    packageName: string;
}

const MobileNavbar: FunctionComponent<Props> = ({
    navItems,
    role,
    openSuggestionModal,
    expires,
    isAccountActive,
    packageName,
}) => {
    const { pathname } = useLocation();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();

    const [menuDropdownVisible, setMenuDropdownVisible] = useState(false);

    const [nbsDropdownVisible, setNbsDropdownVisible] = useState(false);
    const [accountExpires, setAccountExpires] = useState(false);

    const [successfullySaved, setSuccessfullySaved] = useState(false);

    const isAdmin = localStorage.getItem('role') === 'admin' ? true : false;

    const handleSelect = (text: string): void => {
        if (!useChangeModalStore.getState().changeModalVisible) {
            if (text === t('navbar.pausal.nbs').text) {
                setNbsDropdownVisible(true);
                return;
            } else if (text === t('navbar.pausal.suggestion').text) openSuggestionModal();
        }
    };

    return (
        <>
            {accountExpires && <AccountExpiration close={() => setAccountExpires(false)} />}
            <MobileContainer>
                {successfullySaved && (
                    <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                        <Success
                            close={() => setSuccessfullySaved(false)}
                            message={t('pages.agency.profile.saveSuccess').text}
                        />
                    </Modal>
                )}
                <Nav className={accountExpires ? 'hide' : ''}>
                    <Container>
                        <Link style={{ cursor: 'pointer' }} to="/invoices">
                            <img src={logo} />
                        </Link>

                        <RightSide>
                            {!isAdmin ? (
                                <div className="notification-bell-wrapper">
                                    <NotificationsController />
                                </div>
                            ) : null}

                            <div className="user-dropdown-wrapper">
                                <div
                                    onClick={() => {
                                        setMenuDropdownVisible(false);
                                    }}
                                    className="user-icon-wrapper"
                                >
                                    <UserSettingsController />
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    setMenuDropdownVisible((prev) => !prev);
                                }}
                            >
                                <FontAwesomeIcon color="white" icon={faBars} style={{ fontSize: '26px' }} />
                            </div>
                        </RightSide>
                    </Container>

                    {menuDropdownVisible && (
                        <BurgerContainer>
                            <>
                                {navItems.map((navItem, index) => {
                                    let action: () => void;

                                    switch (navItem.actionType) {
                                        case 'toggleNbs':
                                            action = (): void => {
                                                setNbsDropdownVisible(true);
                                            };
                                            break;
                                        default:
                                            action = (): void => {
                                                setMenuDropdownVisible(false);
                                            };
                                            break;
                                    }

                                    const handleClick = (): void => {
                                        handleSelect(t(navItem.text).text);
                                        action && action();
                                    };

                                    return (
                                        <NavItem {...navItem} action={handleClick} key={navItem.text + `${index}`} />
                                    );
                                })}
                                {nbsDropdownVisible && (
                                    <NbsContainer>
                                        <Nbs height="auto" width="100%" onClose={() => setNbsDropdownVisible(false)} />
                                    </NbsContainer>
                                )}
                            </>
                            {role && role !== 'admin' && (
                                <>
                                    {isAccountActive ? (
                                        <AccountExpires
                                            onClick={() => {
                                                navigate('/settings/subscription/1');
                                                setMenuDropdownVisible(false);
                                            }}
                                        >
                                            <p>
                                                {t('navbar.pausal.expires').text +
                                                    ' ' +
                                                    moment(expires).format('DD.MM.YYYY.')}
                                            </p>
                                            <p>{packageName}</p>
                                        </AccountExpires>
                                    ) : (
                                        <AccountExpired>
                                            <Button
                                                variant={ButtonVariant.solid}
                                                color={colors.danger}
                                                size={190}
                                                onClick={() => navigate('/settings/subscription/1')}
                                            >
                                                {t('navbar.pausal.accountExpired').text}
                                            </Button>
                                        </AccountExpired>
                                    )}
                                    <SelectContainer>
                                        <SelectLang />
                                    </SelectContainer>
                                    {/* {isAccountActive ? (
                                        <ButtonContainer>
                                            <Link to="/independenceTest">
                                                <button
                                                    className="digital-test"
                                                    onClick={() => {
                                                        setMenuDropdownVisible(false);
                                                    }}
                                                >
                                                    {t('navbar.pausal.independenceTest').text}
                                                </button>
                                            </Link>
                                        </ButtonContainer>
                                    ) : (
                                        <></>
                                    )} */}
                                </>
                            )}
                            {role && role === 'admin' && !pathname.includes('admin') && <Navigate to="/admin/users" />}
                        </BurgerContainer>
                    )}
                </Nav>
            </MobileContainer>
        </>
    );
};
const MobileContainer = styled.div`
    .modal-wrapper {
        max-width: 100%;
    }

    @media only screen and (max-width: 425px) {
    }
`;
const Nav = styled.div`
    &.hide {
        display: none;
    }
`;

const Container = styled.div`
    top: 0;
    width: 100%;
    max-width: 100%;
    background: var(--purple);
    overflow: visible;
    padding: 10px 30px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    > a {
        display: flex;
        height: 35px;
        img {
            height: 100%;
        }
    }
`;

const RightSide = styled.div`
    display: flex;
    align-items: center;
    gap: 1.2rem;
    .user-dropdown-wrapper {
        position: relative;
    }
    .user-icon-wrapper,
    .notification-bell-wrapper {
        background-color: var(--white);
        color: var(--purple);
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        .user-icon,
        .bell-icon {
            font-size: 1.1rem;
        }
    }
`;

const BurgerContainer = styled.div`
    width: 100%;
    background: var(--purple);
    margin-top: 56px;
    padding-bottom: 124px;
    height: calc(100vh - 56px);
    overflow-y: scroll;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 999;
    float: none;

    position: fixed;
`;

const SelectContainer = styled.div`
    padding-left: 30px;
    margin-top: 25px;

    > div {
        height: 36px;
        width: 100px;
    }
`;

const NbsContainer = styled.div`
    height: 680px;
    position: absolute;
    width: 400px;
    max-width: 90%;
    z-index: 9999;
    overflow-y: scroll;
    left: 50%;
    transform: translateX(-50%);
`;
export default MobileNavbar;
