import { queryClient } from '../../App';
import communication from '../../communication';
import { GET_NotificationsResponse } from '../../communication/notifications/types';
import { useMutation } from '@tanstack/react-query';
import { ObjectKeys } from '../../types/objectKeys';

const markAsRead = (alerts_ids: string[]) => {
    return communication.markAsRead({ alerts_ids });
};

export const useMarkAsRead = (params: ObjectKeys) => {
    return useMutation({
        mutationFn: (alerts_ids: string[]) => markAsRead(alerts_ids),
        onMutate: async (alerts_ids) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: ['notifications'] });

            // Snapshot the previous value
            const previousNotificationData = queryClient.getQueryData(['notifications']);

            // Optimistically update to the new value
            queryClient.setQueryData(
                ['notifications', params],
                (old: GET_NotificationsResponse['data'] | undefined) => {
                    return {
                        ...old,
                        data: old?.data.map((notification) => {
                            if (alerts_ids.includes(notification.id)) {
                                return {
                                    ...notification,
                                    alert_users: {
                                        ...notification.alert_users,
                                        data: notification.alert_users.data.map((dataItem, index) =>
                                            index === 0 ? { ...dataItem, is_read: 1 } : dataItem,
                                        ),
                                    },
                                };
                            } else {
                                return notification;
                            }
                        }),
                    };
                },
            );

            // Return a context object with the snapshotted value
            return { previousNotificationData };
        },
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        onError: (err, _alert_ids, context) => {
            queryClient.setQueryData(['notifications', params], context?.previousNotificationData);
            console.error(err);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['notifications', params] });
        },
    });
};
