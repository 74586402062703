import { useNotificationsQuery } from '../../react-query/useNotificationsQuery';
import useOutsideClick from '../../hooks/useOutsideClick';
import { faArrowTurnUp, faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import parse from 'html-react-parser';
import useLanguageStore from '../../store/language';
import { useMarkAsRead } from '../../pages/notifications/useMarkAsRead';
import { ObjectKeys } from '../../types/objectKeys';

export const NotificationsController = () => {
    const [showNotifications, setShowNotifications] = useState<boolean>(false);
    const { currentLangName } = useLanguageStore();

    const [params, setParams] = useState<ObjectKeys>({
        page: 1,
        limit: 10,
        search: '',
        searchFields: '',
        searchJoin: 'and',
        orderBy: 'created_at',
        sortedBy: 'desc',
    });

    const { notifications } = useNotificationsQuery(params);

    const unreadNotifications = notifications?.data?.filter(
        (notification) => !notification.alert_users.data[0].is_read,
    );

    const unreadNotificationsCount = (unreadNotifications?.length || 0) <= 9 ? unreadNotifications?.length : '9+';

    const ref = useRef<HTMLDivElement>(null);

    useOutsideClick(ref, setShowNotifications);

    const { mutate: markAsRead } = useMarkAsRead(params);

    const toggleNotifications = () => {
        setShowNotifications((prev) => !prev);
    };

    const handleMarkAllAsRead = () => {
        const unreadNotificationsIds = notifications?.data
            .slice(0, 4)
            ?.filter((notf) => notf.alert_users.data[0].is_read === 0)
            ?.map((notif) => notif.id);
        if (unreadNotificationsIds && unreadNotificationsIds?.length > 0) {
            markAsRead(unreadNotificationsIds || []);
        }
    };

    return (
        <NotificationsWrapper ref={ref}>
            <button className="bell-button" onClick={toggleNotifications}>
                <FontAwesomeIcon
                    className={`bell-icon ${unreadNotificationsCount && 'has-unread-notifications'}`}
                    icon={faBell}
                />
                {!!unreadNotificationsCount && <span className="notification-count">{unreadNotificationsCount}</span>}
            </button>
            {showNotifications && (
                <div className="notifications-dropdown">
                    <button className="mark-all-as-read-button" onClick={handleMarkAllAsRead}>
                        PREGLEDANO
                    </button>
                    <ul className="notifications-list">
                        {notifications?.data?.slice(0, 4).map((notification, index) => (
                            <li
                                key={'notification-' + index}
                                onClick={() => {
                                    if (!notification.alert_users.data[0].is_read) {
                                        markAsRead([notification.id]);
                                    }
                                }}
                            >
                                <Link
                                    className={`notification-item ${
                                        !notification.alert_users.data[0].is_read && 'is-unread'
                                    }`}
                                    to={`/notifications?id=${notification.id}`}
                                    onClick={toggleNotifications}
                                >
                                    <FontAwesomeIcon icon={faArrowTurnUp} className="arrow-icon" fontSize={'1.2rem'} />
                                    <div>
                                        <p className="title">
                                            {currentLangName === 'Srpski'
                                                ? notification.title_of_alert_rs
                                                : currentLangName === 'English'
                                                ? notification.title_of_alert_en
                                                : notification.title_of_alert_ru}
                                        </p>
                                        <p className="description">
                                            {parse(
                                                currentLangName === 'Srpski'
                                                    ? notification.description_rs
                                                    : currentLangName === 'English'
                                                    ? notification.description_en
                                                    : notification.description_ru,
                                            )}
                                        </p>
                                    </div>
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <Link to={'/notifications'}>
                        <button className="view-all-button" onClick={toggleNotifications}>
                            POGLEDAJ SVE PORUKE
                        </button>
                    </Link>
                </div>
            )}
        </NotificationsWrapper>
    );
};

const NotificationsWrapper = styled.div`
    .bell-button {
        all: unset;
        cursor: pointer;
        position: relative;
        .bell-icon {
            color: var(--purple);
            &.has-unread-notifications {
                color: var(--toastify-color-error);
            }
        }
        .notification-count {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 13px;
            color: var(--white);
            margin-top: -1px;
        }
    }
    .notifications-dropdown {
        position: absolute;
        top: 3.5rem;
        width: 300px;
        right: 100px;
        max-height: 450px;
        overflow: scroll;
        padding: 0.5rem 0;
        background-color: var(--body-gray);
        border: 2px solid var(--purple);
        z-index: 100;
        box-sizing: border-box;
        @media only screen and (max-width: 1079px) {
            width: 100vw;
            left: 50%;
            transform: translateX(-50%);
        }
        .mark-all-as-read-button {
            all: unset;
            cursor: pointer;
            font-size: 14px;
            text-align: right;
            margin-bottom: 1.5rem;
            margin-left: auto;
            padding: 0 1rem;
            display: block;
            &:hover {
                color: var(--purple);
            }
        }
        .notifications-list {
            display: flex;
            flex-direction: column;
            margin-bottom: 1.5rem;
            li {
                list-style: none;
                .notification-item {
                    cursor: pointer;
                    display: flex;
                    gap: 1rem;
                    padding: 0.5rem 1rem;
                    &:hover {
                        background-color: white;
                        .title {
                            text-decoration: underline;
                        }
                    }
                    &.is-unread {
                        .title {
                            font-weight: bold;
                        }
                    }
                    .arrow-icon {
                        transform: rotate(90deg);
                        color: var(--purple);
                    }
                    .title {
                        font-size: 14px;
                        color: var(--black);
                        margin-bottom: 0.5rem;
                    }
                    .description {
                        font-size: 12px;
                        line-height: 1.4;
                        color: var(--gray);
                        font-style: italic;
                        margin: 0;
                    }
                }
            }
        }
        .view-all-button {
            all: unset;
            font-size: 14px;
            width: 100%;
            text-align: center;
            cursor: pointer;
            color: var(--black);
            &:hover {
                color: var(--purple);
            }
        }
    }
`;
