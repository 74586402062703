import Checkbox from '../checkbox';
import { transformDateFormat } from '../../functions/formatDateTime';
import { Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { NotificationType } from '@/communication/notifications/types';
import parse from 'html-react-parser';
import useLanguageStore from '../../store/language';

interface Props extends NotificationType {
    checked: boolean;
    onCheck: (id: string) => void;
    onClick: any;
}

export const NotificationItem = ({
    alert_users,
    checked,
    onCheck,
    onClick,
    id,
    title_of_alert_rs,
    title_of_alert_en,
    title_of_alert_ru,
    description_rs,
    description_en,
    description_ru,
    created_at,
}: Props) => {
    const [searchParams] = useSearchParams();
    const searchId = searchParams.get('id');

    const { currentLangName } = useLanguageStore();

    return (
        <Notification className={`${!alert_users.data[0].is_read ? 'is-not-read' : ''}`}>
            <div className="first-row">
                <div className="checkbox-title-wrapper">
                    <Checkbox defaultChecked={checked} onChange={() => onCheck(id)} />
                    <Link
                        to={`/notifications${searchId !== id ? `?id=${id}` : ''}`}
                        onClick={() => {
                            if (!alert_users.data[0].is_read) {
                                onClick([id]);
                            }
                        }}
                    >
                        {currentLangName === 'Srpski'
                            ? title_of_alert_rs
                            : currentLangName === 'English'
                            ? title_of_alert_en
                            : title_of_alert_ru}
                    </Link>
                </div>
                <p>{transformDateFormat(created_at)}</p>
            </div>
            {searchId === id && (
                <div className="second-row">
                    {parse(
                        currentLangName === 'Srpski'
                            ? description_rs
                            : currentLangName === 'English'
                            ? description_en
                            : description_ru,
                    )}
                </div>
            )}
        </Notification>
    );
};

const Notification = styled.div`
    padding: 1.5rem;
    background-color: var(--white);
    cursor: pointer;
    color: var(--black);
    &:hover {
        background-color: #cebcff;
    }
    &:not(:last-child) {
        border-bottom: 1px solid var(--border-color);
    }
    .checkbox-title-wrapper {
        display: flex;
        gap: 0.5rem;
        a {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 50ch;
            color: var(--black);
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &.is-not-read {
        a {
            font-weight: bold;
        }
    }
    .first-row {
        display: flex;
        justify-content: space-between;
    }
    .second-row {
        padding: 2rem;
    }
`;
